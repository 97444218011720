.stat-block {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3.5vh;
    color: white;
    max-width: 800px;
    gap: 5px;
}
  
@media screen and (max-height: 670px) {
  .stat-block {
    margin-top: 5px;
  }
}

@media screen and (max-height: 570px) {
  .stat-block {
    margin-top: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-height: 540px) {
  .stat-item > div {
    min-height: 20px !important;
  }
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 1px;
  padding: 5px 0;
  background: #454045;
  border-radius: 15px;
  width: 33%;
}

.stat_text {
  font-size: 15px;
  text-align: center;
  font-weight: 400;
}

.stat_text span {
  font-weight: 700;
  font-size: 18px;
}
  
  .stat-item > div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    min-height: 30px;
  }
  
  .stat-item img {
    width: 30px; 
    height: 30px;
  }
  .stat-item span {
    font-weight: 700;
    font-size: 18px;
  }

@media screen and (max-width: 475px) {
  .stat_text {
    font-size: 13px;
  }
}

@media screen and (max-width: 430px) {
  .stat_text {
    font-size: 11px;
  }
}