.dailyReword {
    .calender_icon {
        width: 105px;
        height: 120px;
        margin-bottom: 20px;
    }

    .dailyText {
        margin-top: 10px;
        font-size: 14px;
    }

    .dailyInfo {
        text-align: center;
        padding: 0 80px;
    }

    .rewords {
        margin-top: 26px;
        margin-bottom: 50px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 6px;
        row-gap: 6px;
        justify-content: center;

        .reword {
            width: 18.5%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: center;
            margin: 0;
            padding: 6px 0;
            border-radius: 10px;
            background: #454045;
            opacity: 0.6;

            h3 {
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
            }

            p {
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
            }

            img {
                width: 28px;
                height: 28px;
                margin: 0;
            }

            &.Available {
                opacity: 1;
                border: 2px solid #52C661;
            }

            &.Claimed {
                opacity: 1;
                background: linear-gradient(180deg, #64F277 0%, #3A8B44 100%);
                box-shadow: 0px -5.016px 5.016px 0px #337C3D inset, 0px 5.016px 5.016px 0px #6AFF7E inset, 0px 10.8px 21.6px 0px rgba(16, 40, 19, 0.48);
            }
        }
    }

    button {
        padding: 28px 0;

        &.disabled {
            background-color: #626262;
        }
    }
}